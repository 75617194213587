/* eslint-disable max-classes-per-file */
import moment from 'moment';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ClientOptions, CountryOptions } from './config';
import { DEFAULT_FOCUS_SKU_PERCENTAGE } from '../pages/previewStrategy/constants';

export enum StrategyTaskType {
    GroupSku = 'group_sku',
    FocusSku = 'focus_sku',
}

export interface SkuInfo {
    full_name: string;
    brand_name: string;
    pid: string;
    bees_category: string;
}

export class SkuGroup {
    id?: number;

    skus: string[];

    client: ClientOptions;

    // optional field added on FE
    skus_full_name?: string[];

    task_name: string;

    group_sku_name: string;

    constructor() {
        this.skus = [];
        this.task_name = '';
        this.group_sku_name = '';
    }
}

export interface PreviouslyPurchasedSkus {
    vendor_accountid: string;
    sku_id: string;
    previously_purchased: boolean;
    type: StrategyTaskType;
}

export interface PreviouslyPurchased {
    previously_purchased_skus: PreviouslyPurchasedSkus[];
}

export interface StrategyOverviewType {
    strategies: StrategiesDataType[];
    poc_locations: PocLocation;
}

export interface StrategiesDataType {
    strategy_id: number;
    name: string;
    goal: string;
    demo_mode?: boolean;
    arms: string[];
    start_date: string;
    end_date: string;
    num_accountids: number;
    revenue: number;
    items: number;
    coverage: number;
    mix: number;
    average_order_value: number;
    num_orders: number;
    quick_order_impression_count: number;
    cross_sell_up_sell_impression_count: number;
    forgotten_items_impression_count: number;
    top_deals_impression_count: number;
    deal_sort_impression_count: number;
    quick_order_order_count: number;
    cross_sell_up_sell_order_count: number;
    forgotten_items_order_count: number;
    top_deals_order_count: number;
    deal_sort_order_count: number;
    active_customers_count: number;
    data: StrategyData;
    created_at: string;
    focus_skus: Record<string, number>;
    group_skus: Record<string, number>;
    status: StrategyStatus | LegacyStrategyStatus;
}

export interface StrategiesDataTypeWithViewedOrdered extends StrategiesDataType {
    viewed: number;
    ordered: number;
}

export interface PocLocation {
    latitude: number[];
    longitude: number[];
    strategy_id: number[];
}

export interface StrategyData {
    status: StrategyStatus | LegacyStrategyStatus;
    country: string;
    demoMode: boolean;
    priority: any;
    products: string[];
    createdBy: string;
    linkedStrategyId: any;
    client: string;
    goal: string;
    name: string;
    mode: string;
    isControl: boolean;
    levers: Record<string, Record<string, boolean>>;
    pocFilters: {
        poc: {
            values: string[];
            include: boolean;
        };
        region: {
            values: string[];
            include: boolean;
        };
    };
    timeframe: {
        startDate: string;
        endDate: string;
    };
    target?: number;
}

export enum LegacyStrategyStatus {
    Preview = 'Preview',
    Scheduled = 'Scheduled',
}

export type StrategyLevers = Record<string, any>;

export enum BEESForceLevers {
    RepRouting = 'Rep Routing',
    RepTasking = 'Rep Tasking',
    POCVisits = 'POC Visits',
    AIGeneratedSellingArguments = 'AI-Generated Selling Arguments',
    CoverageTasks = 'COVERAGE_TASKS',
    GlobalTasks = 'GLOBAL_TASKS',
}

export enum ParentLeverGroup {
    BEES_FORCE = 'BEES Force',
    BEES_GROW = 'BEES Grow',
    BEES_CUSTOMER = 'BEES Customer',
}

export enum BEESCustomerLevers {
    SuggestedOrder = 'SUGGESTED_ORDER',
    UpsellPopup = 'UPSELL',
    ForgottenItems = 'FORGOTTEN_ITEMS',
    TopDeals = 'Top Deals',
    DealSort = 'DEAL_SORT',
    Promos = 'Promos',
    BannerImage = 'Banner Image',

    // digital comms subgroup

    Email = 'Email',
    TextMessageWhatsApp = 'Text Message / WhatsApp',
    PushNotification = 'Push Notification',
    InAppMessaging = 'In-App Messaging',
    InstagramFacebookAds = 'Instagram / Facebook Ads',

    // club b subgroup

    ClubBEarn = 'Earn',
    ClubBRedeem = 'Redeem',
    ClubBChallenge = 'Challenge',
}

export enum BEESGrowLevers {
    POCPrioritization = 'POC Prioritization',
    MissionPrioritization = 'Mission Prioritization',
    AIGeneratedCallScripts = 'AI-Generated Call Scripts',
}

export const levers: Lever[] = [
    {
        name: BEESForceLevers.RepRouting,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESForceLevers.RepTasking,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESForceLevers.POCVisits,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESForceLevers.AIGeneratedSellingArguments,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESForceLevers.CoverageTasks,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESForceLevers.GlobalTasks,
        group: ParentLeverGroup.BEES_FORCE,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.SuggestedOrder,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.UpsellPopup,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.ForgottenItems,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.TopDeals,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.DealSort,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: true,
        disabled: false,
    },
    {
        name: BEESCustomerLevers.Promos,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.BannerImage,
        group: ParentLeverGroup.BEES_CUSTOMER,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.Email,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Digital Comms',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.TextMessageWhatsApp,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Digital Comms',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.PushNotification,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Digital Comms',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.InAppMessaging,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Digital Comms',
        defaultOn: false,
        disabled: true,
    },

    {
        name: BEESCustomerLevers.InstagramFacebookAds,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Digital Comms',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.ClubBEarn,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Club B',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.ClubBRedeem,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Club B',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESCustomerLevers.ClubBChallenge,
        group: ParentLeverGroup.BEES_CUSTOMER,
        subGroup: 'Club B',
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESGrowLevers.POCPrioritization,
        group: ParentLeverGroup.BEES_GROW,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESGrowLevers.MissionPrioritization,
        group: ParentLeverGroup.BEES_GROW,
        defaultOn: false,
        disabled: true,
    },
    {
        name: BEESGrowLevers.AIGeneratedCallScripts,
        group: ParentLeverGroup.BEES_GROW,
        defaultOn: false,
        disabled: true,
    },
];

export const disabledLevers = levers.filter(lever => lever.disabled).map(lever => lever.name);

export const defaultLevers = levers.reduce(
    (acc, curr) => {
        const { group, name, defaultOn } = curr;
        acc[group][name] = defaultOn;
        return acc;
    },
    {
        [ParentLeverGroup.BEES_FORCE]: {} as { [key: string]: boolean },
        [ParentLeverGroup.BEES_GROW]: {} as { [key: string]: boolean },
        [ParentLeverGroup.BEES_CUSTOMER]: {} as { [key: string]: boolean },
    },
);

export enum StrategyStatus {
    CREATED = 'created',
    GENERATING_PREVIEW = 'generating_preview',
    PREVIEW = 'preview',
    SCHEDULED = 'scheduled',
    ACTIVE = 'active',
    COMPLETED = 'completed',
    INACTIVE = 'inactive',
}

export class Strategy {
    id?: number;

    goal: Goal; // required

    name?: string;

    target?: number;

    created_at?: string;

    regions: string[];

    accountids: string[];

    start_date: string;

    end_date: string;

    client?: ClientOptions;

    country?: CountryOptions;

    demo_mode?: boolean;

    status?: StrategyStatus | LegacyStrategyStatus;

    arms: string[];

    arms_enabled: boolean;

    ui_enabled: boolean;

    is_control?: boolean;

    custom_account_id_file: string;

    strategy_task_percentage: number;

    auto_optimize: boolean;

    /** @deprecated Unused by frontend. Kept for compatibility with backend. */
    is_active: boolean;

    focus_skus?: Record<string, number>;

    group_skus?: Record<string, number>;

    data: {
        linkedStrategyId?: number;
        createdBy?: string;
        status: StrategyStatus | LegacyStrategyStatus;
        products: string[]; // legacy strategies
        priority: number;
        demoMode?: boolean;
        country?: CountryOptions;
        distributors?: string[];
        uploadedPOCListFileName?: string;
        businessTypes?: string[];
    };

    constructor(
        client: ClientOptions,
        createdBy: string,
        demoMode: boolean,
        country: CountryOptions,
    ) {
        // Calculate the first day of the upcoming month
        const firstDayOfNextMonth = moment().add(1, 'month').startOf('month');

        // Calculate the last day of the upcoming month
        const lastDayOfNextMonth = moment(firstDayOfNextMonth).endOf('month');

        this.start_date = firstDayOfNextMonth.format('MM/DD/YYYY');
        this.end_date = lastDayOfNextMonth.format('MM/DD/YYYY');

        this.arms_enabled = true;
        this.ui_enabled = true;
        this.client = client;
        this.regions = [];
        this.accountids = [];
        this.custom_account_id_file = '';
        this.strategy_task_percentage = DEFAULT_FOCUS_SKU_PERCENTAGE;
        this.auto_optimize = true;
        this.is_active = true; // legacy
        this.is_control = false;
        this.demo_mode = !!demoMode;
        this.country = country;
        this.data = {
            createdBy,
            linkedStrategyId: null,
            status: LegacyStrategyStatus.Preview,
            products: [],
            priority: null,
            distributors: [],
            uploadedPOCListFileName: '',
            businessTypes: [],
            country,
            demoMode,
        };
    }

    /* 
        WARNING: DO NOT add methods or extend this class. Many parts of the code base 
        use  StrategyWithFocusSkus (type not class) instead of Strategy. So any methods 
        added here will not be available since those objects extend  the type not the class.
    */
}

export interface Lever {
    name: string;
    group: ParentLeverGroup;
    subGroup?: string;
    defaultOn: boolean;
    disabled: boolean;
}

export enum Goal {
    DriveDigitalAdoption = 'Drive Digital Adoption',

    IncreaseMix = 'Increase Mix',

    IncreaseCoverage = 'coverage',

    GrowVolume = 'volume',
    GrowRevenue = 'revenue',
}

export enum StrategyStartTimePeriodOptions {
    Continuous = 'Never (Continuous)',
    After1Month = '1',
    After2Months = '2',
    ChooseSpecificDate = 'Choose Specific Date',
}

export enum PocGroupBy {
    PocChannelType = 'channel_type',
    PocChannel = 'channel',
    PocSize = 'size',
    ProductMix = 'sku_coverage',
    DigitalMaturity = 'digital-maturity',
}

export enum MapTimeFrame {
    OneDay = '1 day',
    SevenDays = '7 days',
    OneMonth = '1 month',
}

export enum StatsMode {
    AllPocs = 'all-pocs',
    PerPoc = 'per-poc',
}

export enum ChartMetric {
    Revenue = 'revenue',
    Coverage = 'coverage',
    Mix = 'mix',
}

export enum ChartMetricType {
    Cumulative = 'cumulative',
    Daily = 'daily',
}

export interface CumulativeMetrics {
    date: string;
    hectaLitersDaily: number;
    hectaLitersCumulative: number;
    revenueDaily: number;
    revenueCumulative: number;
    coverageDaily: number;
    coverageCumulative: number;
    mixDaily: number;
    mixCumulative: number;
}

export interface BuyerGroups {
    firstTime: number;
    twoOrders: number;
    threePlusOrders: number;
}

export const clientGoals: Record<ClientOptions, Goal[]> = {
    [ClientOptions.AlphaC]: [Goal.GrowVolume, Goal.GrowRevenue, Goal.IncreaseCoverage],
    [ClientOptions.Neptune]: [
        Goal.GrowRevenue,
        Goal.DriveDigitalAdoption,
        Goal.IncreaseCoverage,
        Goal.GrowVolume,
        Goal.IncreaseMix,
    ],
};

export interface Recommendation {
    product_id: string;
    product_name: any;
    vendor_item_id: any;
    quantity: number;
    rating: number;
    vendor_id: string;
    store_id: string;
    recommendation_id_type: string;
    vendor_account_id: string;
    user_rating: number;
    pid: string;
    full_name: string;
    brand_name: string;
    bees_category: string;
}

export type TaskRunningStatus = 'running' | 'not-running';
export interface TaskGenerationStatus {
    status: TaskRunningStatus;
    strategyId: string;
}

export type SortDirection = 'asc' | 'desc';

export type ProductOptionForGroups = {
    value: string;
    label: string;
};
export type ProductOption = {
    value: string;
    label: string;
    group: string;
};

export type SKUGroupsRefetch = (options?: RefetchOptions) => Promise<
    QueryObserverResult<
        {
            group_skus: SkuGroup[];
        },
        Error
    >
>;

export interface DeleteStrategyPropsType {
    onSuccess?: (
        data: any,
        variables: {
            id: number;
        },
        context: void,
    ) => unknown;
    onError?: (
        error: Error,
        variables: {
            id: number;
        },
        context: void,
    ) => unknown;
    onMutate?: (variables: { id: number }) => void | Promise<void>;
    onSettled?: (
        data: any,
        error: Error,
        variables: {
            id: number;
        },
        context: void,
    ) => unknown;
}

export interface FilterValuesResponseType {
    poc_filter_values: Record<string, string[]>;
    product_filter_values: Record<string, string[]>;
}

export interface AutoOptimizePriorities {
    task_priorities: TaskPriority[];
}

export interface TaskPriority {
    task_name: string;
    task_subtype: StrategyTaskType;
    priority: number;
}

export interface LatestExportedRecs {
    exported_recs: ExportedRec[];
}

export interface ExportedRec {
    product_id: string;
    product_name: string;
    vendor_item_id: string;
    quantity: number;
    rating: number;
    vendor_id: string;
    store_id: string;
    recommendation_id_type: string;
    vendor_account_id: string;
    user_rating: number;
}

export interface GetStrategy {
    strategy: Strategy;
}

export interface ListGroupSkus {
    group_skus: SkuGroup[];
}

export interface AvailableExclusionChannels {
    channels: string[];
}

export type Exclusion = { type: StrategyTaskType; channel_exclusions: string[] };

export interface StrategyWithFocusSkus extends Strategy {
    focus_skus: Record<string, number>;
    group_skus: Record<string, number>;
    exclusions: Record<string, Exclusion>;
}

export interface StrategyDataAlt {
    strategy: StrategyWithFocusSkus;
}

export interface AccountsInfo {
    accounts: Account[];
}

export interface Account {
    vendor_accountid: string;
    latitude: number;
    longitude: number;
    channel: string;
    channel_type: string;
    city: string;
    sku_coverage: string;
    distributor: string;
    distributor_name: string;
    distribution_center_code: string;
    customer_delivery_region_code: string;
    customer_delivery_region_name: string;
}

export interface UpdateStrategyResponse {
    strategy: Strategy;
}
export interface ScheduleStrategyResponse {
    strategy: Strategy;
}
export interface UnScheduleStrategyResponse {
    strategy: Strategy;
}
export interface RegenerateTasksResponse {
    job_link: string;
    status: string;
}
export interface UpdateGroupSkuResponse {
    group_sku: GroupSku;
}

export interface GroupSku {
    id: number;
    group_sku_name: string;
    task_name: string;
    skus: string[];
    client: string;
}
export interface UpdateSkuPrioritiesResponse {
    sku_priorities: SkuPriority[];
}

export interface SkuPriority {
    sku: string;
    priority: number;
    task_type: string;
}
export interface TaskSummary {
    strategy_id: number;
    sku_summary: SkuSummary[];
    potential_summary: PotentialSummary[];
}

export interface SkuSummary {
    sku: string;
    number_of_tasks: number;
}

export interface PotentialSummary {
    potential: string;
    number_of_tasks: number;
}

export interface CreateGroupSkuResponse {
    id: number;
    group_sku_name: string;
    task_name: string;
    skus: string[];
    client: string;
}
export interface AddGroupSkuToStrategy {
    strategy: Strategy;
}
export interface AddFocusSkuToStrategy {
    strategy: Strategy;
}
export type ExclusionUpdates = {
    sku_id: string;
    sku_type: StrategyTaskType;
    sku_exclusions: string[];
};
export interface UpdateExclusionsResponse {
    strategy: Strategy;
}
export interface ArchiveGroupSkuResponse {
    group_sku: GroupSku;
}
export interface UnArchiveGroupSkuResponse {
    group_sku: GroupSku;
}

export type PotentialCategory = 'high' | 'med' | 'low';

export interface SkuTaskBreakdown {
    tasks: {
        vendor_accountid: string;
        task_name: string;
        task_type: string;
        task_subtype: string;
        skus: string[];
        predicted_potential_category: PotentialCategory;
        previously_purchased: boolean;
    }[];
    strategy_task_percentage: number;
    priorities: {
        sku: string;
        priority: number;
        type: string;
    }[];
}

export interface DailySummayDataPoint {
    date: string;
    revenue: number;
    coverage: number;
    mix: number;
}

export interface DailySummaryCalculatedResponse {
    date: string;
    revenueDaily: number;
    revenueCumulative: number;
    coverageDaily: number;
    coverageCumulative: number;
    mixDaily: number;
    mixCumulative: number;
}
export interface DeleteStrategyResponse {
    id: number;
    msg: string;
}
export interface DailySummaryType {
    daily_summary: DailySummary[];
}

export interface DailySummary {
    date: string;
    revenue: number;
    coverage: number;
    mix: number;
}
export interface StrategyOrdersMetrics {
    strategy_id: number;
    average_order_value: number;
    num_orders: number;
}

export interface TasksBreakdown {
    strategy_id: string;
    vendor_accountid: string;
    latitude: number;
    longitude: number;
    digital_education_effective_task_count: number;
    digital_education_completed_task_count: number;
    digital_education_total_task_count: number;
    execution_effective_task_count: number;
    execution_completed_task_count: number;
    execution_total_task_count: number;
    simple_effective_task_count: number;
    simple_completed_task_count: number;
    simple_total_task_count: number;
    coverage_effective_task_count: number;
    coverage_completed_task_count: number;
    coverage_total_task_count: number;
    visit_duration_seconds: number;
}
export interface StrategyForecast {
    forecast: Forecast[];
}

export interface Forecast {
    strategy_id: number;
    date: string;
    revenue: number;
    revenue_cumsum: number;
    quantity: number;
    quantity_cumsum: number;
    weight: number;
    weight_cumsum: number;
}

export interface AggregatedMetric {
    vendor_accountid: string;
    revenue: number;
    mix: number;
    quick_order_impression_count: number;
    cross_sell_up_sell_impression_count: number;
    forgotten_items_impression_count: number;
    top_deals_impression_count: number;
    deal_sort_impression_count: number;
    quick_order_order_count: number;
    cross_sell_up_sell_order_count: number;
    forgotten_items_order_count: number;
    top_deals_order_count: number;
    deal_sort_order_count: number;
    quick_order_impression_count3: number;
    cross_sell_up_sell_impression_count3: number;
    forgotten_items_impression_count3: number;
    top_deals_impression_count3: number;
    deal_sort_impression_count3: number;
    quick_order_order_count3: number;
    cross_sell_up_sell_order_count3: number;
    forgotten_items_order_count3: number;
    top_deals_order_count3: number;
    deal_sort_order_count3: number;
    customer_delivery_region_code: string;
    channel_type: string;
    channel: string;
    size: string;
    sku_coverage: string;
    latitude: number;
    longitude: number;
}

export interface DiffResult {
    percentDiff: number;
    metricDiff: number;
    value: number;
    totalPocs: number;
    pocsThatPurchased: number;
}

export interface TableRow {
    segmentName: string;
    totalPocs: number;
    pocsThatPurchased: number;
    metricValue: number;
    averageOrderValue: number;
    comparisons: Array<DiffResult & { key: ChartDataSet }>;
    id: string;
}

export enum ChartDataSet {
    LastMonthCurrentStrategy = 'lastMonthCurrentStrategy',
    TwoMonthsAgoCurrentStrategy = 'twoMonthsAgoCurrentStrategy',
    ThreeMonthsAgoCurrentStrategy = 'threeMonthsAgoCurrentStrategy',
    LastYearCurrentStrategy = 'lastYearCurrentStrategy',
    AltStrategy = 'altStrategy',
    CurrentStrategy = 'currentStrategy',
}
export interface DataSegment {
    aggregated_metric: number;
    average_order_value: number;
    group_by_name: string;
    pocs_in_group: number;
    pocs_that_purchased: number;
}
export interface StrategyBreakdownResponse {
    poc_breakdown: DataSegment[];
}

export interface StrategyAccount {
    vendor_accountid: string;
    strategy_id: number;
    name: string;
    latitude: number;
    longitude: number;
    customer_delivery_region_code: string;
    customer_delivery_region_name: string;
    channel: string;
    channel_type: string;
    distributor_name: string;
    size: string;
    sku_coverage: string;
}

export interface StrategyTaskMetrics {
    sku_coverage: string;
    size: string;
    channel_type: string;
    channel: string;
    customer_delivery_region_name: string;
    longitude: number;
    latitude: number;
    strategy_id_: string;
    digital_education_effective_task_count: number;
    digital_education_completed_task_count: number;
    digital_education_total_task_count: number;
    execution_effective_task_count: number;
    execution_completed_task_count: number;
    execution_total_task_count: number;
    simple_effective_task_count: number;
    simple_completed_task_count: number;
    simple_total_task_count: number;
    coverage_effective_task_count: number;
    coverage_completed_task_count: number;
    coverage_total_task_count: number;
    visit_duration_seconds: number;
    strategy_id: string;
    date: string;
    vendor_accountid: string;
}

export interface PocSkuDateMetrics {
    vendor_accountid: string;
    pid: string;
    date: string;
    revenue: number;
    liters: number;
    quick_order_impression_count: number;
    quick_order_order_count: number;
    cross_sell_up_sell_impression_count: number;
    cross_sell_up_sell_order_count: number;
    forgotten_items_impression_count: number;
    forgotten_items_order_count: number;
    top_deals_impression_count: number;
    top_deals_order_count: number;
    deal_sort_impression_count: number;
    deal_sort_order_count: number;
    quick_order_impression_count3: number;
    quick_order_order_count3: number;
    cross_sell_up_sell_impression_count3: number;
    cross_sell_up_sell_order_count3: number;
    forgotten_items_impression_count3: number;
    forgotten_items_order_count3: number;
    top_deals_impression_count3: number;
    top_deals_order_count3: number;
    deal_sort_impression_count3: number;
    deal_sort_order_count3: number;
    account_pid: string;
    brand_name: string;
    product_type: string;
    bees_category: string;
    strategy_id_: string;
    latitude: number;
    longitude: number;
    customer_delivery_region_name: string;
    channel: string;
    channel_type: string;
    size: string;
    sku_coverage: string;
    strategy_id: string;
}

export interface PocExplorerReponseType {
    account_info: Account;
    visits: Visit[];
    strategy_info: {
        id: number;
        name: string;
    };
}

export interface PocExplorerSummaryReponseType {
    num_mapped_pocs: number;
    num_strategy_pocs: number;
    accounts_no_visit: string[];
    accounts_duplicate_visits: string[];
    accounts_no_tasks_today_not_in_strategy: string[];
    accounts_no_tasks_today_not_mapped: string[];
    accounts_no_tasks_today_not_allocated_tasks: string[];
    accounts_duplicate_tasks_today: string[];
}

export interface Visit {
    visit_id: string;
    visit_status: string;
    date: string;
    bdr_id: string;
    adjusted_visit_duration?: number;
    tasks: Task[];
}
export interface Task {
    name: string;
    task_type: string;
    sub_type: string;
    category: string;
    sku_list: string[];
    task_date: string;
    is_completed: boolean;
    is_effective: boolean;
}

export type StrategyChannelsResponseType = {
    channels: string[];
};
