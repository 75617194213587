// TODO: some of these colors were pulled at an opacity from figma, go back and fix

export const helmColors = {
    lightGray: '#e4e4e8',
    lightGray2: '#d7d9de',
    lightGray3: '#EEEEF2',
    gray1: '#b1b5c1',
    gray2: '#81859b',
    gray3: '#E4E4E8',
    lightBlue: '#e0e4f6',
    darkBlue: '#345dee',
    darkGreyBlue: '#515674',
    lightGrayBlue: '#f7f9fa',
    lightPink: '#ece5f2',
    pink: '#f11b97',
    yellow: '#EBA338',
    tealGreen: '#0082AC',
    lightTealGreen: '#E5F2F7',
    purple: '#6938b9',
    periwinkleBlue: '#c7cdf2',
    skyBlue: '#bdd7e9',
    seafoamGreen: '#c8ded2',
    paleChartreuse: '#dae1c7',
    magenta: '#bb54b5',
    lilac: '#dabcd5',
    lavender: '#e2bfcd',
    rose: '#e6c4c4',
    coral: '#e6cabd',
    red: '#D14E5F',
    crimson: '#e94576',
    green: '#4C9355',
    lightGreen: '#94C544',
    navy: '#08103A',
    primaryBlue: '#345DEE',
};

export const cardLinearGradient = 'linear-gradient(112.87deg, #F6F8F9 0%, #F2F0F6 100%)';

export const cardLinearGradientWithWhiteBGLayer =
    'linear-gradient(112.87deg, #F6F8F9 0%, #F2F0F6 100%), white';
