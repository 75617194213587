/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { ReactNode } from 'react';
import cx from 'classnames';
import HelmTooltip from '../helmTooltip';
import { Button } from '../../../../andromeda/Button';

interface PrimaryButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    tooltipLabel?: string;
    rightSection?: ReactNode;
    leftSection?: ReactNode;
    className?: string;
    variant?: 'primary' | 'secondary' | 'tertiary';
    onClick: () => void;
}
export const PrimaryButton = ({
    onClick,
    children,
    tooltipLabel,
    leftSection,
    rightSection,
    className,
    variant,
    disabled = false,
}: PrimaryButtonProps) => {
    const button = (
        <Button
            className={cx('!px-4 !h-8', className)}
            variant={variant}
            onClick={onClick}
            leftSection={leftSection}
            rightSection={rightSection}
            disabled={disabled}
        >
            {children}
        </Button>
    );

    if (tooltipLabel) {
        return (
            <HelmTooltip label={tooltipLabel} position="top">
                <div> {button}</div>
            </HelmTooltip>
        );
    }
    return button;
};
