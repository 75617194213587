import { filterQueryParams, toQueryString } from './util';

// TODO: maybe separate routes for track and preview isn't the best, could be just a /strategy/:id route and then render depending on status
export const HelmRoutes = {
    HOME: (queryParams: Record<string, any> = {}) =>
        `/${toQueryString(filterQueryParams(queryParams))}`,
    CREATE_STRATEGY: (queryParams: Record<string, any> = {}) =>
        `/create-strategy${toQueryString(filterQueryParams(queryParams))}`,
    CREATE_STRATEGY_WITH_STRATEGY_ID_TO_DUPLICATE: (queryParams: Record<string, any> = {}) =>
        `/create-strategy/${toQueryString(queryParams)}`,
    TRACK_STRATEGY: (id: number, queryParams: Record<string, any> = {}) =>
        `/track-strategy/${id}${toQueryString(filterQueryParams(queryParams))}`,
    PREVIEW_STRATEGY: (id: number, queryParams: Record<string, any> = {}) =>
        `/preview-strategy/${id}${toQueryString(filterQueryParams(queryParams))}`,
    EDIT_STRATEGY: (id: number, queryParams: Record<string, any> = {}) =>
        `/edit-strategy/${id}${toQueryString(filterQueryParams(queryParams))}`,
    TASKS_AND_GROUPS_EDITOR: (queryParams: Record<string, any> = {}) =>
        `/tasks-and-groups-editor/${toQueryString(filterQueryParams(queryParams))}`,
    POC_EXPLORER: (queryParams: Record<string, any> = {}) =>
        `/poc-explorer/${toQueryString(filterQueryParams(queryParams))}`,
    POC_UPLOADER: (queryParams: Record<string, any> = {}) =>
        `/poc-uploader/${toQueryString(filterQueryParams(queryParams))}`,
};
