import { Button as ButtonMantine, ButtonProps } from '@mantine/core';
import React, { ButtonHTMLAttributes, FC, useMemo, useState } from 'react';
import cx from 'classnames';
import Color from 'color';
import StyledTooltip from '../components/utils/styledTooltip';
import { colorsScheme, primaryColors } from '../constants/colors';
import useColorScheme from '../hooks/useColorScheme';

type ExtraButtonProps = {
    onClick?: () => void;
    disabledTooltipLabel?: string;
    variant?: 'primary' | 'secondary' | 'tertiary';
    containerClassName?: string;
    id?: string;
    inactive?: boolean;
};

export const Button: FC<
    ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps & ExtraButtonProps
> = ({
    variant = 'primary',
    disabledTooltipLabel,
    children,
    className,
    disabled,
    classNames,
    containerClassName,
    id,
    inactive,
    ...rest
}) => {
    const [isMouseInside, setIsMouseInside] = useState(false);

    const { colorScheme } = useColorScheme();
    const inactiveColor = useMemo(() => {
        if (!inactive) return undefined;
        let color: string;
        if (variant === 'primary') color = Color(primaryColors.blue).alpha(0.2).hexa();
        if (variant === 'secondary') color = Color(primaryColors.blue).alpha(0.2).hexa();
        if (variant === 'tertiary')
            color = Color(colorsScheme[colorScheme].polarisGreen).alpha(0.2).hexa();
        return color;
    }, [colorScheme, inactive, variant]);

    return (
        <StyledTooltip
            label={disabledTooltipLabel}
            position="bottom"
            disabled={!disabled || rest?.loading || !disabledTooltipLabel}
        >
            <div className={cx('w-fit', containerClassName)}>
                <ButtonMantine
                    {...rest}
                    id={id}
                    disabled={disabled || inactive}
                    onMouseEnter={() => setIsMouseInside(true)}
                    onMouseLeave={() => setIsMouseInside(false)}
                    styles={{
                        section: {
                            color:
                                variant === 'secondary'
                                    ? (isMouseInside && 'white !important') ||
                                      `${primaryColors.blue} !important`
                                    : 'inherit',
                        },
                        inner: { color: 'inherit' },
                        root: {
                            background:
                                variant === 'secondary'
                                    ? isMouseInside && inactiveColor
                                    : inactiveColor,
                        },
                    }}
                    classNames={classNames}
                    className={
                        cx(
                            className,
                            '!rounded-full !transition-colors !ease-in-out !duration-100',
                            {
                                '!bg-risd-blue !text-xs !font-normal hover:!bg-risd-blue hover:!opacity-80 focus:focus-within:!bg-risd-blue':
                                    variant === 'primary',
                            },
                            {
                                'text-navy-solid-50 text-sm font-normal hover:!text-white bg-transparent active:bg-opacity-60 active:bg-risd-blue hover:bg-risd-blue':
                                    variant === 'secondary',
                            },
                            {
                                'bg-polaris-green text-xs font-normal hover:bg-polaris-green hover:opacity-80 text-black':
                                    variant === 'tertiary',
                            },
                        ) as string
                    }
                    loaderProps={{ type: 'dots' }}
                >
                    {children}
                </ButtonMantine>
            </div>
        </StyledTooltip>
    );
};
