import { Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { SignOut } from 'phosphor-react';
import { useCallback, useState } from 'react';
import { Check } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../contexts/appContext';
import { PrimaryButton } from './buttons/primaryButton';
import { helmColors } from '../util/colors';

const NOTIF_SETTINGS = {
    disallowClose: true,
    autoClose: 5000,
};

const HelmLogout = () => {
    const { logOut, user } = useAppContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const logout = useCallback(() => {
        if (!user?.token) return;
        setLoading(true);
        logOut(user.token);
        showNotification({
            ...NOTIF_SETTINGS,
            title: 'Logout Successful',
            message: 'You have been logged out of the system.',
            color: 'green',
            icon: <Check />,
        });
        setLoading(false);
    }, [logOut, user?.token]);

    return (
        <PrimaryButton
            className="!text-navy-15 !font-semibold"
            leftSection={!loading && <SignOut size={20} color={helmColors.lightGray2} />}
            onClick={logout}
            disabled={loading}
        >
            {loading ? (
                <Loader speed={1} size={15} color="white" />
            ) : (
                `${t('log out from')} One Brain`
            )}
        </PrimaryButton>
    );
};

export default HelmLogout;
