import { useMantineColorScheme } from '@mantine/core';
import { useColorScheme as useColorSchemeMantineMantine, useLocalStorage } from '@mantine/hooks';
import { useEffect } from 'react';
import { useAppContext } from '../contexts/appContext';
import { colorsScheme } from '../constants/colors';

type ColorScheme = 'light' | 'dark';

function useColorScheme(): {
    colorScheme: ColorScheme;
    toggleColorScheme: () => void;
    colors: any;
} {
    const { user } = useAppContext();
    const { colorScheme, toggleColorScheme, setColorScheme } = useMantineColorScheme();
    const preferredColorScheme = useColorSchemeMantineMantine();
    const [localStorageColorScheme] = useLocalStorage<ColorScheme>({
        key: 'color-scheme',
        defaultValue: 'light',
    });

    const colors = colorsScheme[colorScheme];

    useEffect(() => {
        setColorScheme('light');
    }, []);

    useEffect(() => {
        // ? only For Arena Group
        if (!user?.groups?.includes('arena-staff')) {
            if (colorScheme !== 'light') setColorScheme('light');
            return;
        }
        setColorScheme('light');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorScheme, preferredColorScheme, user?.groups]);

    return {
        colorScheme: 'light',
        toggleColorScheme,
        colors,
    };
}

export default useColorScheme;
