import React from 'react';
import { Tooltip, TooltipProps } from '@mantine/core';
import { helmColors } from '../util/colors';

const HelmTooltip: React.FC<TooltipProps> = ({ children, ...props }) => (
    <Tooltip
        {...props}
        classNames={{
            tooltip: '!z-[10001]',
        }}
        styles={{
            tooltip: {
                backgroundColor: helmColors.navy,
            },
        }}
    >
        {children}
    </Tooltip>
);

export default HelmTooltip;
