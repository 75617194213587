/* eslint-disable @typescript-eslint/naming-convention */

import moment, { Moment } from 'moment';
import { TFunction } from 'i18next';
import { ChartLine, Laptop, ListNumbers, Package, Storefront } from 'phosphor-react';
import _, { capitalize } from 'lodash';
import { capFirstLetterAndLowerOthers } from '../../../utils/productFilters';
import { StrategyOverviewPartial } from '../../../hooks/helm/strategyOverviewPartial';
import {
    Account,
    BuyerGroups,
    ChartMetric,
    clientGoals,
    defaultLevers,
    Goal,
    MapTimeFrame,
    PocGroupBy,
    SkuGroup,
    SkuInfo,
    StrategiesDataType,
    StrategiesDataTypeWithViewedOrdered,
    Strategy,
    StrategyLevers,
} from './types';
import { ClientOptions, CountryOptions, Environment } from './config';
import output_coordinates from './output_coordinates.json';
import { MappedCustomersInfo } from '../pages/createStrategy/types';
import { orientSplitToObjects } from '../../../hooks/helm/util';
import mockCPGProducts from '../../../hooks/helm/mockCPGProducts.json';

export const utcMoment = (str: string | Date | Moment = new Date()) => moment(str);

export const delayAndCallFunction = (delayMs, callback) =>
    new Promise<void>((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, delayMs);
    }).then(() => {
        callback();
    });

export const seasonalDiffWithDayMatch = (
    date: string | Moment | Date,
    amount = 1,
    unit = 'years',
) => {
    const newDate = moment(date);
    const dayOfWeek = newDate.day();

    const oneYearAgo = newDate.clone().subtract(amount, unit as any);

    while (oneYearAgo.day() !== dayOfWeek) {
        oneYearAgo.add(1, 'days');
    }

    return oneYearAgo;
};

export const ALL_PRODUCTS = 'all products';

export const channels = [
    'bakery',
    'bar',
    'club',
    'convenience_store',
    'distributor',
    'entertainment',
    'events',
    'fast_food',
    'food_truck',
    'franchise',
    'gas_station',
    'hotels_lodging',
    'liquor_store',
    'pharmacy',
    'restaurant',
    'school',
    'supermarket',
    'other',
];

export const DISTRIBUTOR_NAME_ACRONYMS = [
    'S.A',
    'CIA.',
    'CIA',
    'LTDA.',
    'LTDA',
    'S.A.',
    'CIA.LTDA',
    'H&B',
    'CHB',
];

export const NESTLE_EC_DISTRIBUTOR_CODE_MAP_BY_GROUP = [
    {
        label: 'Barnuevo',
        value: '1033',
        group: 'Quito',
    },
    {
        label: 'Crads',
        value: '1957',
        group: 'Quito',
    },
    {
        label: 'Equadis',
        value: '548',
        group: 'Quito',
    },
    {
        label: 'Jorge De La Cruz',
        value: '1714',
        group: 'Quito',
    },
    {
        label: 'Paez Maldonado',
        value: '278',
        group: 'Quito',
    },
    {
        label: 'Surtiandinos',
        value: '2092',
        group: 'Quito',
    },
    { label: 'Pasquel Zapata Mario Alfredo', value: '1999', group: 'Sierra Norte Oriente' },
    { label: 'Ramasandistri', value: '1947', group: 'Sierra Norte Oriente' },
    { label: 'Jumbo Center', value: '2089', group: 'Sierra Norte Oriente' },
    { label: 'Granda Laica Luis Salomon', value: '1808', group: 'Sierra Norte Oriente' },
    { label: 'Coberservicios Sociedad Civil', value: '1838', group: 'Costa Norte' },

    { label: 'Marvecobe', value: '1203', group: 'Costa Norte' },
    { label: 'Abatacorp', value: '2033', group: 'Costa Norte' },
    { label: 'Multitiendas', value: '1292', group: 'Costa Norte' },
    { label: 'Cronocosta', value: '1845', group: 'Costa Norte' },
    { label: 'Gladys Moreno Yanez Elizabeth', value: '1914', group: 'Costa Norte' },
    { label: 'Laura Narvaez', value: '1955', group: 'Sierra Centro Sur' },
    { label: 'Prodelta Compania', value: '1822', group: 'Sierra Centro Sur' },
    { label: 'Camel', value: '1421', group: 'Sierra Centro Sur' },
    {
        label: 'Comercial y Comisario Economico Comycomec',
        value: '2101',
        group: 'Sierra Centro Sur',
    },
    { label: 'Olmedo Arias Luis Felipe', value: '1929', group: 'Sierra Centro Sur' },
    {
        label: 'Masivo Distrikazuay',
        value: '1971',
        group: 'Sierra Centro Sur',
    },
    { label: 'Distribuidora Granda', value: '1774', group: 'Sierra Centro Sur' },
    { label: 'Distribuidora Jedasavi', value: '1815', group: 'Sierra Centro Sur' },
    { label: 'Paspuel Vergara Fernando David', value: '1948', group: 'Costa Sur' },
    { label: 'Lovitersa', value: '1837', group: 'Costa Sur' },
    { label: 'Comercial', value: '1349', group: 'Costa Sur' },
    { label: 'Produventas', value: '1875', group: 'Costa Sur' },
    { label: 'Jacome Espinoza Benjamin Ramiro', value: '99', group: 'Costa Sur' },
    { label: 'Carlos Ruilova', value: '1920', group: 'Costa Sur' },
    {
        label: 'Munoz Villaquiran',
        value: '1515',
        group: 'Costa Sur',
    },
    {
        label: 'Hiperstock',
        value: '1874',
        group: 'Costa Sur',
    },
    {
        label: 'Expertisima',
        value: '1380',
        group: 'Costa Sur',
    },
    {
        label: 'Rensu',
        value: '2095',
        group: 'Costa Sur',
    },
];

export const getDistributorNameWithCodeByDistributor = (distributor: string) => {
    const codeFound = NESTLE_EC_DISTRIBUTOR_CODE_MAP_BY_GROUP.find(
        opt => opt.label === distributor,
    )?.value;
    if (codeFound) {
        return `${codeFound} - ${distributor}`;
    }
    return distributor;
};

export const channelDisplay = (segment: string) => {
    if (segment === 'distributor') return 'Wholesaler';
    return segment?.split('_').map(capFirstLetterAndLowerOthers).join(' ');
};

const channelTypeDisplayMap: {
    [key: string]: string;
} = {
    ON: 'On-Prem',
    OFF: 'Off-Prem',
    WH: 'Wholesaler',
};

export const channelTypeDisplay = (channelType: string) =>
    channelTypeDisplayMap[channelType] || channelType;

export const geos = [
    'Quito',
    'Guayaquil',
    'Costa Norte',
    'Costa Sur',
    'Costa Centro',
    'Sierra Sur',
    'Sierra Oriente',
    'Sierra Centro',
];

export function isToday(date: string) {
    if (!date) return false;
    const inputDate = moment(date);
    const today = moment().startOf('day');
    return inputDate.isSame(today, 'day');
}

export type PocFilters = Record<'included' | 'excluded', boolean>;

export const MOCK_TOTAL_POCS = 235131;
export const MOCK_PRIORITIZED_PCT = 0.64;
/* calls Math.random and returns true 0.5% of the time (this just makes it look kind of right) */
export const mockIsPrioritized = () => Math.random() < MOCK_PRIORITIZED_PCT;

export const EXCLUDED_MYSTERY_OCEAN_POCS_IDS = [
    '11801739', // DISBEBIDASUR CIA. LTDA., Jama, Jama, Manabí
    '12890386', // Jordan Vera, Maria Jose, Manta, Manta, Manabí,
    '13702020',
    '11801635',
    '11801629',
    '11801746',
    '13717570',
    '11801831',
    '11801724',
    '11847876', // in Colombia
];

export interface StrategyOverviewMapped extends Omit<StrategyOverviewPartial, 'priority'> {
    vendor_accountid: string;
    region: string;
    product_mix: string;
    revenue?: number;
    coverage?: number;
    metricColors?: any; // todo type or refactor
}

export const regionMapping: { [key: string]: string } = {
    E11: 'Quito',
    E01: 'Guayaquil',
    E32: 'Costa Norte',
    E04: 'Costa Sur',
    E02: 'Sierra Sur',
    E48: 'Costa Centro',
    E43: 'Sierra Oriente',
    E14: 'Sierra Centro',
};

export const mapCustomersInfoData = (data: Account[]) =>
    data
        ?.map(d => ({
            ...d,
            region: regionMapping[d.customer_delivery_region_name], // TODO: why do I need this mapping
        }))
        .filter(d => !EXCLUDED_MYSTERY_OCEAN_POCS_IDS.includes(d.vendor_accountid));

export const customSortProducts = (
    a: { value: string; label: string; group: string },
    b: { value: string; label: string; group: string },
) => {
    const getProductName = (label: string) => {
        if (!label) return '';
        const parts = label?.split('-');
        // If the string has a dash and starts with a number, use the part after the dash for sorting.
        if (parts?.length > 1 && !isNaN(+parts[0].trim())) {
            return parts[1].trim();
        }
        // Otherwise, use the entire string.
        return label?.trim();
    };

    const firstProduct = getProductName(a.label);
    const secondProduct = getProductName(b.label);

    if (firstProduct && secondProduct) {
        return firstProduct.localeCompare(secondProduct);
    }

    return 0; // Equal or invalid labels; they stay in the same order.
};

export const mapSkuResponseToProductOptions = (skus: SkuInfo[]) => {
    const seenProducts = new Set();

    // TODO: remove if unnecessary. Why are there duplicates?
    const uniqueProducts = skus
        .reduce(
            (acc, curr) => {
                if (!seenProducts.has(curr.full_name)) {
                    seenProducts.add(curr.full_name);
                    acc.push({
                        value: `product - ${curr.pid}`,
                        label: curr.full_name,
                        group: 'Product',
                    });
                }
                return acc;
            },
            [] as { value: string; label: string; group: string }[],
        )
        .sort(customSortProducts);

    return [...uniqueProducts];
};

export const mapSkuGroupResponseToGroupOptions = (skuGroups: SkuGroup[]) =>
    skuGroups.map(group => ({
        value: `group - ${group.id}`,
        label: group.group_sku_name,
        group: 'Group',
    }));

export const languageCodeMap = {
    en: 'english',
    es: 'spanish',
};

export const capitalizeFirst = (word: string) => {
    if (!word || word.length === 0) {
        return '';
    }

    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeWords = (value: string) =>
    _.chain(value).split(' ').map(_.capitalize).join(' ').value();

export const buildPOCGroupByOptions = (t: TFunction) => [
    { value: PocGroupBy.PocChannel, label: t('channel') },
    { value: PocGroupBy.PocChannelType, label: t('channel_type') }, // todo: i18n
    { value: PocGroupBy.ProductMix, label: t('distinct_skus') },
    { value: PocGroupBy.PocSize, label: t('size') },
    { value: PocGroupBy.DigitalMaturity, label: t('digital_maturity'), disabled: true },
];

export const defaultPocFilters: PocFilters = {
    included: true,
    excluded: true,
};

const leverGroups = ['BEES_FORCE', 'BEES_GROW', 'BEES_CUSTOMER'];

export const buildMatrixData = (data: any[], strategyRegions: string[], strategyId: string) => {
    // todo; filter in API call?
    if (!data || data.length === 0) return null;
    const filteredData =
        data?.filter(
            d =>
                d.strategy_id === strategyId &&
                strategyRegions.includes(d.customer_delivery_region_name),
        ) || [];

    return Object.values(MapTimeFrame).reduce((acc, curr) => {
        acc[curr] = leverGroups.reduce((acc2, curr2) => {
            // eslint-disable-next-line no-param-reassign
            acc2[curr2] = filteredData.reduce((acc3, curr3) => {
                const { group } = curr3;

                // eslint-disable-next-line no-param-reassign
                acc3[group] = filteredData
                    .filter(d => d.group === group)
                    .map(d => ({
                        x: d.customer_delivery_region_name,
                        y: d.value,
                        v: d[curr2],
                    }));
                return acc3;
            }, {});
            return acc2;
        }, {});
        return acc;
    }, {});
};

export const leverFormatMap = {
    BEESForce: 'BEES_FORCE',
    BEESGrow: 'BEES_GROW',
    BEESCustomer: 'BEES_CUSTOMER',
};

export const leverFormatReverseMap = {
    BEES_FORCE: 'BEESForce',
    BEES_GROW: 'BEESGrow',
    BEES_CUSTOMER: 'BEESCustomer',
};

export function mergeObjects<T>(obj1: T, obj2: T): T {
    if (!obj2) return obj1;
    const mergedObj = { ...obj2 }; // Copy all properties from obj1 to a new object

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(obj1)) {
        // Add value from obj2 only if it's undefined or missing in obj1
        if (mergedObj[key] === undefined) {
            mergedObj[key] = value;
        }
    }

    return mergedObj;
}

export function getGreeting() {
    const date = new Date();
    const hours = date.getHours();
    let greeting;

    if (hours >= 5 && hours < 12) {
        greeting = 'good-morning';
    } else if (hours >= 12 && hours < 18) {
        greeting = 'good-afternoon';
    } else {
        greeting = 'good-evening';
    }

    return greeting;
}

// todo: temp hack

export const metricGoalMap = {
    [Goal.IncreaseCoverage]: ChartMetric.Coverage,
    [Goal.GrowRevenue]: ChartMetric.Revenue,
};

export const identityFn = <T>(val: T) => val as string;

// destructure all fields from poc. All them all up but they may be null
export const calculateTotalImpressionCount = poc => {
    const {
        cross_sell_up_sell_impression_count,
        deal_sort_impression_count,
        forgotten_items_impression_count,
        quick_order_impression_count,
        top_deals_impression_count,
    } = poc;
    return (
        (cross_sell_up_sell_impression_count || 0) +
        (deal_sort_impression_count || 0) +
        (forgotten_items_impression_count || 0) +
        (quick_order_impression_count || 0) +
        (top_deals_impression_count || 0)
    );
};

export const productColumns = ['pid', 'bees_category', 'brand_name'];

export const filterFieldDisplayMap = {
    pid: 'product',
    brand_name: 'Brand',
    bees_category: 'Category',
    customer_delivery_region_name: 'Region',
    channel: 'channel',
    channel_type: 'channel_type',
    sku_coverage: 'distinct_skus',
    size: 'size',
    distributor_name: 'distributor',
};

export const nestleFiltersToHide = ['size', 'sku_coverage', 'channel_type'];
export const abiFilterToHide = ['distributor_name', 'sku_coverage'];

export const MAX_FILTER_ITEM_LENGTH = 15;
export const truncate = (
    val: string,
    length = MAX_FILTER_ITEM_LENGTH,
    showEllipsis = true,
): string => {
    if (!val || val.length <= length) {
        return val;
    }

    const truncatedVal = val.slice(0, length).trim();
    return showEllipsis ? `${truncatedVal}...` : truncatedVal;
};
export const filterItemDisplay = (
    category: keyof typeof filterFieldDisplayMap | 'vendor_accountid',
    item: string,
    shouldTruncate: boolean,
    skuMap: Record<string, string>,
    t,
) => {
    let output = item;
    if (category === 'customer_delivery_region_name') {
        output = item === 'UNKNOWN' ? t('unknown').toUpperCase() : item;
    }
    if (category === 'channel') {
        output = t(channelDisplay(item));
    }
    if (category === 'channel_type') {
        output = t(channelTypeDisplay(item));
    }
    if (category === 'pid') {
        output = skuMap[item]?.replace(' product', '') || item?.replace(' product', '');
    }
    if (category === 'distributor_name') {
        output = getDistributorNameWithCodeByDistributor(item);
    }
    return shouldTruncate ? truncate(output) : output;
};

export const helmMapStyle = {
    mapStyle: 'mapbox://styles/mlgardner/clh9imalh01vm01p497p37a4p',
    mapboxAccessToken:
        'pk.eyJ1IjoibWxnYXJkbmVyIiwiYSI6ImNsaDlpZ3A5djA3cW0zdG4wYjV6YzJ1MmQifQ._ir1PzfXjLH-jsiyqUdt-A',
};

export const metricDisplayMap = {
    [ChartMetric.Revenue]: 'revenue',
    [ChartMetric.Coverage]: 'coverage',
    [ChartMetric.Mix]: 'mix',
};

export const strategyProductDisplay = (products: string[], chartMetric: ChartMetric, t) => {
    // TODO: handle multiple products
    const firstProduct = products ? products[0] : '';
    if (firstProduct === ALL_PRODUCTS) {
        return t(ALL_PRODUCTS);
    }
    if (products?.length === 1) {
        return firstProduct;
    }
    return `${products?.length} ${t('SKU', { count: products?.length })}`;
};

export const calculatePercentDiff = (currentValue: number, otherValue: number) => {
    const diff = currentValue - otherValue;
    const percent = diff / otherValue;
    return {
        diff: Math.abs(diff) < 0.00001 ? undefined : diff,
        percentDiff: Math.abs(percent) < 0.00001 ? undefined : percent,
    };
};

export type BasePOC = { latitude: number; longitude: number; vendor_accountid: string };

export function joinStoreLists(allStores: BasePOC[], storesWithValues: BasePOC[]) {
    const storesWithValuesObj = storesWithValues.reduce((obj, store) => {
        // eslint-disable-next-line no-param-reassign
        obj[store.vendor_accountid] = store;
        return obj;
    }, {});

    return allStores?.map(store => ({ ...storesWithValuesObj[store.vendor_accountid], ...store }));
}

export const buildBuyerGroups = (countsData: { order_count: number }[]): BuyerGroups =>
    countsData?.reduce(
        (acc: any, cur: any) => {
            const orderCount = cur.order_count;
            if (orderCount === 1) {
                // eslint-disable-next-line no-plusplus
                acc.firstTime++;
            } else if (orderCount === 2) {
                // eslint-disable-next-line no-plusplus
                acc.twoOrders++;
            } else {
                // eslint-disable-next-line no-plusplus
                acc.threePlusOrders++;
            }
            return acc;
        },
        { firstTime: 0, twoOrders: 0, threePlusOrders: 0 },
    );

// sorts POC dots by orders: no orders on the bottom, then 3+ orders, then 2 orders, then 1 order
export function customSortPOCSByBuyerGroup(a, b) {
    const aOrders = a.order_count || 0;
    const bOrders = b.order_count || 0;

    // No orders - Sort them first
    if (aOrders === 0 && bOrders !== 0) return -1;
    if (bOrders === 0 && aOrders !== 0) return 1;

    // Sort by the specific order sequence - 3+, 3, 1
    let aOrderWeight = 0;
    let bOrderWeight = 0;

    if (aOrders > 3) {
        aOrderWeight = 2;
    } else if (aOrders === 2) {
        aOrderWeight = 1;
    }

    if (bOrders > 3) {
        bOrderWeight = 2;
    } else if (bOrders === 2) {
        bOrderWeight = 1;
    }

    return bOrderWeight - aOrderWeight;
}

export function createFilterValuesWithoutProductColumns(filterValues: Record<string, string[]>) {
    return Object.keys(filterValues || {}).reduce((acc, curr) => {
        if (!productColumns.includes(curr)) {
            acc[curr] = filterValues[curr];
        }
        return acc;
    }, {});
}

const PARAMS_TO_KEEP = ['client', 'environment', 'demoMode'];

export const filterQueryParams = (params: Record<string, any>) =>
    Object.keys(params)
        .filter(key => PARAMS_TO_KEEP.includes(key))
        .reduce((acc, curr) => {
            acc[curr] = params[curr];
            return acc;
        }, {});

export const excludePocs = [
    '278-RPF0434-PRINCIPAL',
    '1714-100010103-PRINCIPAL',
    '278-RGY0531-PRINCIPAL',
    '278-RCL1726-PRINCIPAL',
    '1714-CLCART2-002773-PRINCIPAL',
    '1714-CLCART2-003936-PRINCIPAL',
    '1714-CLCART2-003911-PRINCIPAL',
    '1714-CLSUP_SEMV000059-PRINCIPAL',
    '1714-CLCART2-003887-PRINCIPAL',
    '278-RCL2103-PRINCIPAL',
    '278-RCL1561-PRINCIPAL',
    '278-QMA0224-PRINCIPAL',
    '1714-100026734-PRINCIPAL',
    '1714-CLCART2-003905-PRINCIPAL',
    '278-RPB0327-PRINCIPAL',
    '1714-108871-PRINCIPAL',
    '278-RCR1040-PRINCIPAL',
    '1714-CLCART2-003889-PRINCIPAL',
    '1714-CLCART2-003891-PRINCIPAL',
    '1714-CLCART2-003908-PRINCIPAL',
    '1714-CLCART2-003907-PRINCIPAL',
    '1714-CLCART2-003913-PRINCIPAL',
    '278-RYR0632-PRINCIPAL',
    '1714-CLCART2-003910-PRINCIPAL',
    '278-TOQ0003-PRINCIPAL',
    '1714-CLCART2-004038-PRINCIPAL',
    '548-436584200036551-PRINCIPAL',
    '1349-55222890005506-PRINCIPAL',
    '1875-0703226894-PRINCIPAL',
    '1875-0928945682001-PRINCIPAL',
    '2092-00000627-PRINCIPAL',
    '2092-00000222-PRINCIPAL',
    '2092-00000352-PRINCIPAL',
    '2092-CLT0X05-000035-PRINCIPAL',
    '2092-00000652-PRINCIPAL',
    '2092-55240760005788-PRINCIPAL',
    '2092-00000614-PRINCIPAL',
    '2092-55819450008199-PRINCIPAL',
    '2092-00000031-PRINCIPAL',
    '2092-00000421-PRINCIPAL',
    '2092-55819450007560-PRINCIPAL',
    '2092-00000561-PRINCIPAL',
    '2092-55819450008911-PRINCIPAL',
    '2092-00000377-PRINCIPAL',
    '2092-00000429-PRINCIPAL',
    '2092-00000317-PRINCIPAL',
    '2092-CLT0X01-000076-PRINCIPAL',
    '2092-55819450008269-PRINCIPAL',
    '2092-CLT0X09000081-PRINCIPAL',
    '2092-55819450008056-PRINCIPAL',
    '2092-CLTF001-000004-PRINCIPAL',
    '2092-00000402-PRINCIPAL',
    '2092-55240760006527-PRINCIPAL',
    '2092-00000230-PRINCIPAL',
    '2092-00000449-PRINCIPAL',
    '2092-00000545-PRINCIPAL',
    '2092-CLT0X02-000029-PRINCIPAL',
    '2092-00000215-PRINCIPAL',
    '2092-B-061-PRINCIPAL',
    '2092-B-122-PRINCIPAL',
    '2092-00000483-PRINCIPAL',
    '2092-CLT0X03-000010-PRINCIPAL',
    '2092-55240760002603-PRINCIPAL',
    '2092-00000413-PRINCIPAL',
    '2092-B-3352-PRINCIPAL',
    '2092-00000075-PRINCIPAL',
    '2092-00000489-PRINCIPAL',
    '2092-00000403-PRINCIPAL',
    '2092-00000639-PRINCIPAL',
    '2092-CLT0X06-000102-PRINCIPAL',
    '2092-55240760006268-PRINCIPAL',
    '2092-00000163-PRINCIPAL',
    '2092-CLT0X03-000030-PRINCIPAL',
    '2092-CLT0X09-000093-PRINCIPAL',
    '2092-00000141-PRINCIPAL',
    '2092-436584200035079-PRINCIPAL',
    '2092-55819450007905-PRINCIPAL',
    '2092-CLT0X05-000066-PRINCIPAL',
    '2092-00000389-PRINCIPAL',
    '2092-CLT0X01-000046-PRINCIPAL',
    '2092-00000963-PRINCIPAL',
    '2092-B-2880-PRINCIPAL',
];

export const excludeSpecialLatLngs = [985134939];

export const sortByField =
    <T, K extends keyof T>(field: K & (T[K] extends number ? K : never)) =>
    (a: T, b: T) => {
        if (a[field] == null && b[field] == null) return 0;
        if (a[field] == null) return -1;
        if (b[field] == null) return 1;
        return (a[field] as any) - (b[field] as any);
    };

export const pluralize = (str: string, count: number) => {
    if (count === 1) return str;
    return `${str}s`;
};

export const getGoalMap = (
    t: any,
): Record<
    Goal,
    { description: string; Icon: React.FunctionComponent<{ size: number | string }> }
> => ({
    [Goal.GrowRevenue]: {
        description: t(`${Goal.GrowRevenue} goal description`),
        Icon: ChartLine,
    },
    [Goal.IncreaseCoverage]: {
        description: t(`${Goal.IncreaseCoverage} goal description`),
        Icon: Storefront,
    },
    [Goal.DriveDigitalAdoption]: {
        description: t(`${Goal.DriveDigitalAdoption} goal description`),
        Icon: Laptop,
    },
    [Goal.IncreaseMix]: {
        description: t(`${Goal.IncreaseMix} goal description`),
        Icon: ListNumbers,
    },
    [Goal.GrowVolume]: {
        description: t(`${Goal.GrowVolume} goal description`),
        Icon: Package,
    },
});

export const convertArmsToStrategyLeversObject = (arms: string[] = []): StrategyLevers =>
    _.mapValues(defaultLevers, value => _.mapValues(value, (val, key) => !!arms.includes(key)));

export const getStrategyArms = (strategyLevers: StrategyLevers): string[] =>
    _.chain(strategyLevers)
        .mapValues(subObj => _.pickBy(subObj, value => value === true))
        .map(subObj => _.keys(subObj))
        .flatten()
        .value();

export const leverDisplayMap = {
    SUGGESTED_ORDER: 'Suggested Order',
    FORGOTTEN_ITEMS: 'Forgotten Items',
    DEAL_SORT: 'Deal Sort',
    UPSELL: 'Upsell',
    COVERAGE_TASKS: 'Coverage Tasks',
    GLOBAL_TASKS: 'Global Tasks',
};

export const getGoalOptions = (client: ClientOptions, t: any) => {
    const goalMap = getGoalMap(t);

    return clientGoals[client]?.map(goal => ({
        value: goal,
        label: t(goal),
        Icon: goalMap[goal].Icon,
        description: goalMap[goal].description,
    }));
};

export const getSKUsListByValue = (
    value: string,
    skuGroups: SkuGroup[],
    productOptionsForGroups: { value: string; label: string }[],
) =>
    skuGroups
        ?.find(skuGroup => `group - ${skuGroup?.id}` === value)
        ?.skus.map(sku => productOptionsForGroups?.find(opt => opt?.label?.includes(sku))?.label);

export const sanitizeAndTransformData = (clipboardData): string[] => {
    const pastedData = clipboardData.getData('text');
    const sanitizedList = pastedData
        .replace(/[^a-zA-Z0-9\s]+/g, '')
        .split(/\s+/)
        .map(sku => `product - ${sku}`) as string[];

    return [...new Set(sanitizedList)];
};

export const getFilteredSanitizedData = (
    e,
    sanitizedData: string[],
    productOptions: {
        value: string;
        label: string;
        group: string;
    }[],
) => {
    const filteredSanitizedData = sanitizedData.filter(item =>
        productOptions.map(p => p.value).includes(item),
    );

    return filteredSanitizedData;
};

export const getInvalidProductsSearchQuery = (
    sanitizedData: string[],
    filteredSanitizedData: string[],
) =>
    sanitizedData
        .filter(item => !filteredSanitizedData.includes(item))
        .map(item => item.split(' ')[2])
        .join(' ');

export const isSelectFocusProductsValueHasDuplicatedSkuError = (
    duplicatedSKUs: string[],
    SKUs: string[],
    label: string,
) => {
    const isSKUsHasDuplicateSku =
        SKUs?.filter(item => duplicatedSKUs.includes(item?.split(' - ')[0])).length > 0;
    const isDuplicatedSKUsIncludeLabel = duplicatedSKUs.includes(label?.split(' - ')[0]);
    return isSKUsHasDuplicateSku || isDuplicatedSKUsIncludeLabel;
};

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

// Function to calculate distance between two points using Haversine formula
function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
    const R = 3958.8; // Radius of the earth in miles
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

function hashStore(obj) {
    let hash = 0;
    // Use only latitude and longitude for hashing
    const lat = obj.latitude;
    const lng = obj.longitude;
    const stringifiedLatLon = `${lat},${lng}`;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < stringifiedLatLon.length; i++) {
        const char = stringifiedLatLon.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + char;
        // eslint-disable-next-line operator-assignment, no-bitwise
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

const mexicoCityLat = 19.4326;
const mexicoCityLng = -99.1332;
const maxDistance = 200; // miles

// Seed the random number generator
function createPseudoRandom(seed) {
    return function () {
        // eslint-disable-next-line no-plusplus, no-param-reassign
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };
}

export const buildNearbyMexicoStores = () => {
    const nearbyMexicoStores = output_coordinates.filter(
        ([longitude, latitude]) =>
            typeof longitude === 'number' &&
            typeof latitude === 'number' &&
            getDistanceFromLatLonInMiles(latitude, longitude, mexicoCityLat, mexicoCityLng) <=
                maxDistance,
    );
    return nearbyMexicoStores;
};

const chosenLocations = {};

function getRandomDistance(minFeet, maxFeet) {
    return Math.random() * (maxFeet - minFeet) + minFeet;
}

function moveLocation(lat, lng, minDistanceInFeet, maxDistanceInFeet) {
    const randomDistance = getRandomDistance(minDistanceInFeet, maxDistanceInFeet);
    const earthRadiusInFeet = 3958.8 * 5280; // earth radius in feet
    const deltaLat = (randomDistance / earthRadiusInFeet) * (180 / Math.PI);
    const deltaLng = deltaLat / Math.cos(lat * (Math.PI / 180));

    const angle = Math.random() * 360; // random angle
    const latOffset = deltaLat * Math.cos(angle * (Math.PI / 180));
    const lngOffset = deltaLng * Math.sin(angle * (Math.PI / 180));

    return [lat + latOffset, lng + lngOffset];
}
export function mapStoreToDemoLocation(store, nearbyMexicoStores) {
    const seed = hashStore(store);
    const pseudoRandom = createPseudoRandom(seed);

    try {
        if (nearbyMexicoStores.length === 0) {
            throw new Error('No stores found within the specified range.');
        }

        const randomIndex = Math.floor(pseudoRandom() * nearbyMexicoStores.length);
        let [longitude, latitude] = nearbyMexicoStores[randomIndex];

        const key = `${latitude},${longitude}`;
        if (chosenLocations[key]) {
            [latitude, longitude] = moveLocation(latitude, longitude, 200, 5280); // move between 200 feet and 50 miles away
            chosenLocations[key] += 1;
        } else {
            chosenLocations[key] = 1;
        }

        const newStore = {
            ...store,
            latitude,
            longitude,
        };
        return newStore;
    } catch (e) {
        return store;
    }
}

export const HIDE_LAST_YEAR_COMPARISON = true;

const sortStrategies = (
    aId: number,
    bId: number,
    aCreatedAt: string,
    bCreatedAt: string,
    environment: Environment,
) => {
    const devOrder = [2, 1, 5, 45, 48, 49];
    const prodOrder = [13, 8, 9, 10, 18, 17, 11, 14, 6, 7, 2, 1, 5];
    const order = environment === 'prod' || environment === 'alpha' ? prodOrder : devOrder;

    const aSpecial = order.includes(aId);
    const bSpecial = order.includes(bId);

    if (!aSpecial && !bSpecial) {
        return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime();
    }

    if (aSpecial && !bSpecial) return 1;
    if (!aSpecial && bSpecial) return -1;

    if (aSpecial && bSpecial) {
        const aIndex = order.indexOf(aId);
        const bIndex = order.indexOf(bId);
        return aIndex - bIndex;
    }

    return 0;
};

export const buildDemoStrategyName = (isControl: boolean, goal: string, startDate: string) =>
    `${isControl ? 'Control' : ''} Grow ${capitalizeFirst(goal)} ${moment(startDate).format(
        'MMMM',
    )}`;

const filterStrategiesBasedOnDemoMode = (strategies: Strategy[], demoMode: boolean) => {
    if (demoMode) {
        return (strategies || []).map(s => ({
            ...s,
            name:
                s.data.demoMode || s.demo_mode
                    ? s.name
                    : buildDemoStrategyName(s.is_control, s.goal, s.start_date),
        }));
    }
    return (strategies || []).filter(d => !(d.data.demoMode || d.demo_mode));
};

export const formatStrategiesData = (
    strategies: Strategy[],
    client: ClientOptions,
    environment: Environment,
    country: CountryOptions,
    demoMode: boolean,
): Strategy[] =>
    filterStrategiesBasedOnDemoMode(strategies, demoMode)
        ?.map((strategy: Strategy) => ({
            ...strategy.data,
            ...strategy,
            id: strategy.id,
        }))
        ?.filter(strategy => {
            if (client === ClientOptions.AlphaC) {
                return strategy.client === ClientOptions.AlphaC || !strategy.client;
            }
            // else Nestle
            return strategy.client === ClientOptions.Neptune;
        })
        .filter(strategy => {
            const strategyCountry = strategy.data.country || CountryOptions.Ecuador; // EC strategies were created before country was included. Fall back to EC
            return strategyCountry === country;
        })
        .sort((a, b) => sortStrategies(a.id, b.id, a.created_at, b.created_at, environment));

export const formatOverviewStrategiesData = (
    strategies: StrategiesDataTypeWithViewedOrdered[],
    environment: Environment,
    country: CountryOptions,
    demoModeParam: boolean,
): StrategiesDataTypeWithViewedOrdered[] => {
    const filteredStrategiesBasedOnDemoMode = (function () {
        if (demoModeParam) {
            return strategies?.map(strategy => {
                const { data, demo_mode: topLevelDemoMode, name: topLevelName, goal } = strategy;
                const { isControl, timeframe, demoMode: legacyDemoMode, name: legacyName } = data;
                const isDemoMode = topLevelDemoMode || legacyDemoMode;
                const name = topLevelName || legacyName;
                return {
                    ...strategy,
                    name: isDemoMode
                        ? name
                        : buildDemoStrategyName(isControl, goal, timeframe?.startDate),
                };
            });
        }
        return (strategies || []).filter(d => !d.data.demoMode);
    })();

    return filteredStrategiesBasedOnDemoMode
        ?.filter((strategy: StrategiesDataType) => {
            const strategyCountry = strategy.data.country || CountryOptions.Ecuador; // EC strategies were created before country was included. Fall back to EC
            return strategyCountry === country;
        })
        .sort((a, b) =>
            sortStrategies(a.strategy_id, b.strategy_id, a.created_at, b.created_at, environment),
        );
};

export const toQueryString = (params: Record<string, any>) => {
    const processedParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value === null || value === undefined) {
            acc[key] = ''; // Or handle it differently if needed
        } else if (typeof value === 'object' && !Array.isArray(value)) {
            acc[key] = '[object Object]'; // Or handle nested objects differently
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});

    return Object.keys(processedParams).length
        ? `?${new URLSearchParams(processedParams).toString()}`
        : '';
};

export const getStrategyFocusSKUsDefaultState = (
    focusSKUs: Record<string, number>,
    groupSKUs: Record<string, number>,
) => {
    const focusSkusIds = Object.keys(focusSKUs);
    const groupSkusIds = Object.keys(groupSKUs);

    return [
        ...focusSkusIds.map(focusSkuId => `product - ${focusSkuId}`),
        ...groupSkusIds.map(groupSkuId => `group - ${groupSkuId}`),
    ];
};

export const areDatesInDifferentMonths = (startDate: string, endDate: string): boolean => {
    const formats = ['MM/DD/YYYY', 'YYYY-MM-DD'];

    if (!moment(startDate, formats, true).isValid() || !moment(endDate, formats, true).isValid()) {
        throw new Error('Invalid date format');
    }

    const startMonth = moment(startDate).month();
    const endMonth = moment(endDate).month();
    return startMonth !== endMonth;
};

export const removeEmptyArraysFromObject = (obj: Record<string, any[]>): Record<string, any[]> =>
    _.omitBy(obj, value => _.isArray(value) && _.isEmpty(value));

export const formatDistributorName = (distributorName: string) =>
    distributorName
        ?.split(' ')
        .map(str => {
            if (DISTRIBUTOR_NAME_ACRONYMS.includes(str)) return str;
            return capitalize(str);
        })
        .join(' ');

export const filterAccountIdsData = (
    customersInfoData: Account[],
    uploadedPOCList: string[],
    uploadedPOCListFileName: string,
    selectedDistributorsLength: number,
    selectedBusinessTypesLength: number,
) => {
    if (
        !uploadedPOCListFileName &&
        !(selectedDistributorsLength > 0) &&
        !(selectedBusinessTypesLength > 0)
    ) {
        return [];
    }
    if (uploadedPOCListFileName) {
        return uploadedPOCList.filter(accountId => Boolean(accountId));
    }
    return customersInfoData
        .map(poc => poc.vendor_accountid)
        .filter(accountId => Boolean(accountId));
};

export const filterMapData = (
    mappedCustomersInfoData: MappedCustomersInfo[],
    uploadedPOCList: string[],
    uploadedPOCListFileName: string,
    selectedDistributorsLength: number,
    selectedBusinessTypesLength: number,
) => {
    if (
        !uploadedPOCListFileName &&
        !(selectedDistributorsLength > 0) &&
        !(selectedBusinessTypesLength > 0)
    ) {
        return [];
    }

    if (uploadedPOCListFileName) {
        return mappedCustomersInfoData.filter(item =>
            uploadedPOCList.includes(item.vendor_accountid),
        );
    }

    return mappedCustomersInfoData;
};

export const getMissingAccountIdsLength = (
    customersInfoData: any[],
    pocListFileName: string,
    accountIds: string[],
) => {
    if (pocListFileName) {
        return _.difference(
            accountIds,
            customersInfoData.map(item => item.vendor_accountid),
        ).filter(Boolean).length;
    }
    return 0;
};

export const getDistributorNameWithCode = (distributorCode: string = '', demoMode = false) => {
    if (demoMode) {
        const randomInt = Math.floor(Math.random() * 1000) + 1;
        return `Distributor ${randomInt}`;
    }
    const foundDistributor = NESTLE_EC_DISTRIBUTOR_CODE_MAP_BY_GROUP.find(
        item => item.value === distributorCode,
    );
    const formattedDistributorName = formatDistributorName(foundDistributor?.label);

    if (foundDistributor?.value === formattedDistributorName) {
        return formattedDistributorName;
    }

    if (foundDistributor?.value && formattedDistributorName) {
        return `${foundDistributor?.value} - ${formattedDistributorName}`;
    }
    if (formattedDistributorName) {
        return formattedDistributorName;
    }
    return distributorCode;
};

export const parseSafeJSON = (text: string) => {
    const safeText = text.replace(/NaN/g, 'null');
    return JSON.parse(safeText);
};

export const getAllDistributorCodes = () =>
    NESTLE_EC_DISTRIBUTOR_CODE_MAP_BY_GROUP.map(distributor => distributor.value);

export function getSafeAPIResult<T>(text: string): T[] {
    const parsedSafeJSON = parseSafeJSON(text);
    const result = orientSplitToObjects<T>(parsedSafeJSON);
    return result;
}

function seedableRandom(seed) {
    // eslint-disable-next-line no-plusplus, no-param-reassign
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export const mapProductToMock = item => {
    const randomIndex = Math.floor(
        seedableRandom(item.pid || Math.random()) * mockCPGProducts.length,
    );
    if (typeof item === 'string') {
        return mockCPGProducts[randomIndex];
    }
    return {
        ...item,
        full_name: mockCPGProducts[randomIndex],
    };
};
