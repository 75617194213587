/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Drawer, ScrollArea, Switch } from '@mantine/core';
import { ArrowRight, Check, Gear, ListChecks, Storefront, Upload } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import cx from 'classnames';
import Link from 'next/link';
import styled from 'styled-components';
import HelmLogout from './helmLogout';
import useStore from './state/state';
import { useAppContext } from '../../../contexts/appContext';
import { clientIdMap, ClientOptions, CountryOptions, Environment } from '../util/config';
import useRouterQuery from '../../../hooks/useRouterQuery';
import { HelmRoutes } from '../util/routes';
import { helmColors } from '../util/colors';
import { useClientTranslation } from '../../../hooks/helm/useClientTranslation';
import { capitalizeFirst } from '../util/util';

const HoverableLink = styled.div`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
        linear-gradient(113deg, rgba(249, 249, 249, 0.5) 0%, rgba(240, 234, 243, 0.5) 100%);
    box-shadow: 8px 8px 16px 0px rgba(10, 12, 15, 0.05);
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    border-left: 0.5px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);

    transition:
        background 0.5s ease,
        box-shadow 0.5s ease,
        border 0.5s ease;

    &:hover {
        border-top: 0.5px solid rgba(0, 0, 0, 0.2);
        border-left: 0.5px solid rgba(0, 0, 0, 0.2);
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0.7) 100%
            ),
            linear-gradient(113deg, rgba(249, 249, 249, 0.5) 0%, rgba(240, 234, 243, 0.5) 100%);
        box-shadow: 2px 2px 4px 0px rgba(10, 12, 15, 0.1);
    }
`;

const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
];

const clientOptions = [
    { value: ClientOptions.AlphaC, label: 'ABI' },
    { value: ClientOptions.Neptune, label: 'Nestle' },
];

const environmentOptions: {
    value: Environment;
    label: string;
}[] = [
    { value: 'alpha', label: 'Alpha' },
    { value: 'dev', label: 'Dev' },
    { value: 'prod', label: 'Prod' },
];

const countryOptions = [
    { value: CountryOptions.Ecuador, label: 'Ecuador' },
    { value: CountryOptions.Phillipines, label: 'Phillipines' },
];

export const Settings = () => {
    const { user, environment, setEnvironment } = useAppContext();
    const { router, handleURLParamsChange } = useRouterQuery();
    const { t, i18n } = useClientTranslation();
    const client = useStore(state => state.client);
    const setClient = useStore(state => state.setClient);
    const country = useStore(state => state.country);
    const setCountry = useStore(state => state.setCountry);
    const demoMode = useStore(state => state.demoMode);
    const setDemoMode = useStore(state => state.setDemoMode);
    const forceShowRealSkuNames = useStore(state => state.forceShowRealSkuNames);
    const setForceShowRealSkuNames = useStore(state => state.setForceShowRealSkuNames);
    const showCreatedBy = useStore(state => state.showCreatedBy);
    const setShowCreatedBy = useStore(state => state.setShowCreatedBy);

    const [drawerOpened, { open: drawerOpen, close: drawerClose }] = useDisclosure(false);

    const onChangeClient = useCallback(
        (newClient: ClientOptions) => {
            const { asPath } = router;
            const isStrategyPage =
                asPath.includes('track-strategy') || asPath.includes('edit-strategy');
            const clientValue = clientIdMap[newClient];
            localStorage.setItem('client', newClient);
            setClient(newClient);

            if (!isStrategyPage) {
                handleURLParamsChange({ key: 'client', value: clientValue });
                drawerClose();
            }

            if (isStrategyPage) {
                router.replace(
                    HelmRoutes.HOME({
                        client: clientValue,
                    }),
                );
            }
        },
        [handleURLParamsChange, setClient, router, drawerClose],
    );

    const onChangeCountry = useCallback(
        (newCountry: CountryOptions) => {
            const { asPath } = router;
            const isStrategyPage =
                asPath.includes('track-strategy') || asPath.includes('edit-strategy');

            setCountry(newCountry);

            if (!isStrategyPage) {
                handleURLParamsChange({ key: 'country', value: newCountry });
                drawerClose();
            }

            if (isStrategyPage) {
                router.replace(
                    HelmRoutes.HOME({
                        country: newCountry,
                    }),
                );
            }
        },
        [handleURLParamsChange, router, setCountry, drawerClose],
    );

    const onChangeDemoMode = useCallback(
        (newDemoMode: boolean) => {
            setDemoMode(newDemoMode);
            handleURLParamsChange({ key: 'demoMode', value: newDemoMode });
        },
        [handleURLParamsChange, setDemoMode],
    );

    const onChangeForceShowRealSkuNames = useCallback(
        (newForceShowRealSkuNames: boolean) => {
            setForceShowRealSkuNames(newForceShowRealSkuNames);
            handleURLParamsChange({
                key: 'forceShowRealSkuNames',
                value: newForceShowRealSkuNames,
            });
        },
        [handleURLParamsChange, setForceShowRealSkuNames],
    );

    const onChangeEnvironment = useCallback(
        (newEnvironment: Environment) => {
            setEnvironment(newEnvironment);
            handleURLParamsChange({ key: 'environment', value: newEnvironment });
        },
        [setEnvironment, handleURLParamsChange],
    );

    const changeLanguage = useCallback(
        language => {
            i18n.changeLanguage(language);
            drawerClose();
        },
        [i18n, drawerClose],
    );

    const showMoreConfig = useMemo(
        () => user.groups.filter(group => ['arena-staff'].includes(group)).length > 0,
        [user.groups],
    );

    const showClientOptions = useMemo(() => {
        const hasBothABIandNestle =
            user.groups.filter(group => ['abi-users', 'nestle-users'].includes(group))?.length ===
            2;
        const isArenaStaff =
            user.groups.filter(group => ['arena-staff'].includes(group)).length > 0;
        return hasBothABIandNestle || isArenaStaff;
    }, [user.groups]);

    return (
        <div className="absolute right-2 top-2 z-[100000]">
            <div className="flex items-center space-x-2">
                <div className="mt-[2px] text-sm font-semibold text-gray-600">
                    <button className="group" type="button" onClick={drawerOpen}>
                        <div
                            style={{
                                padding: '7px',
                                borderRadius: '4px',
                                border: '1px solid #E4E4E8',
                                background: 'rgba(255, 255, 255, 0.50)',
                            }}
                            className="!border-navy-15 backdrop-blur"
                        >
                            <Gear size={16} className={cx('text-navy-70 group-hover:!text-navy')} />
                        </div>
                    </button>
                    <Drawer
                        classNames={{
                            content: '!bg-white',
                            header: '!bg-white !pb-0 !pt-3',
                            inner: '!z-[100002]',
                            body: '!pb-3',
                            overlay: '!z-[100002]',
                        }}
                        opened={drawerOpened}
                        onClose={drawerClose}
                        position="right"
                        size={350}
                        scrollAreaComponent={ScrollArea.Autosize}
                    >
                        <div>
                            <div className="mt-4 flex flex-col gap-2 text-sm text-navy">
                                <Link
                                    className="w-full"
                                    href={HelmRoutes.TASKS_AND_GROUPS_EDITOR(router.query)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <HoverableLink className="mr-2 flex h-full cursor-pointer items-center rounded p-4">
                                        <div className="mr-4 h-1/2">
                                            <ListChecks size={36} color={helmColors.darkGreyBlue} />
                                        </div>
                                        <div className="flex h-1/2 items-center justify-center text-center">
                                            <div>{t('Groups & Task Editor')}</div>
                                        </div>
                                    </HoverableLink>
                                </Link>
                                <Link
                                    href={HelmRoutes.POC_EXPLORER(router.query)}
                                    className="w-full"
                                >
                                    <HoverableLink className="mr-2 flex h-full cursor-pointer items-center rounded p-4">
                                        <div className="mr-4 h-1/2">
                                            <Storefront size={36} color={helmColors.darkGreyBlue} />
                                        </div>
                                        <div className="flex h-1/2 items-center justify-center text-center">
                                            <div>{capitalizeFirst(t('POC Explorer'))}</div>
                                        </div>
                                    </HoverableLink>
                                </Link>
                                <Link
                                    href={HelmRoutes.POC_UPLOADER(router.query)}
                                    className="w-full"
                                >
                                    <HoverableLink className="mr-2 flex h-full cursor-pointer items-center rounded p-4">
                                        <div className="mr-4 h-1/2">
                                            <Upload size={36} color={helmColors.darkGreyBlue} />
                                        </div>
                                        <div>{t('POC Uploader')}</div>
                                    </HoverableLink>
                                </Link>
                            </div>

                            <hr className="mt-10" />
                            {showMoreConfig && (
                                <Link href="https://spaceport.arena-ai.com/hermes" target="_blank">
                                    <div className="hover:text-navy-50 mt-2 flex items-center space-x-2 text-navy">
                                        <div>Data Pipeline</div>
                                        <ArrowRight size={16} />
                                    </div>
                                </Link>
                            )}

                            <div className="text-navy-50 mt-4">{t('language')}:</div>
                            <hr className="my-2" />
                            {languageOptions.map(option => (
                                <button
                                    type="button"
                                    key={option.value}
                                    onClick={() => changeLanguage(option.value)}
                                    className="text-navy-70 my-1 flex w-full cursor-pointer items-center justify-start hover:text-navy"
                                >
                                    {option.label}
                                    {option.value === i18n.language && (
                                        <Check className="ml-2" color="green" />
                                    )}
                                </button>
                            ))}

                            {showClientOptions && (
                                <div>
                                    <div className="text-navy-50 mt-6">{t('client')}:</div>
                                    <hr className="my-2" />
                                    {clientOptions.map(option => (
                                        <button
                                            type="button"
                                            key={option.value}
                                            onClick={() =>
                                                onChangeClient(option.value as ClientOptions)
                                            }
                                            className="text-navy-70 my-1 flex w-full cursor-pointer items-center justify-start hover:text-navy"
                                        >
                                            {option.label}
                                            {option.value === client && (
                                                <Check className="ml-2" color="green" />
                                            )}
                                        </button>
                                    ))}
                                </div>
                            )}

                            {showMoreConfig && (
                                <div>
                                    <div className="text-navy-50 mt-6">{t('country')}:</div>
                                    <hr className="my-2" />
                                    {countryOptions.map(option => (
                                        <button
                                            type="button"
                                            key={option.value}
                                            onClick={() =>
                                                onChangeCountry(option.value as CountryOptions)
                                            }
                                            className="text-navy-70 my-1 flex w-full cursor-pointer items-center justify-start hover:text-navy"
                                        >
                                            {option.label}
                                            {option.value === country && (
                                                <Check className="ml-2" color="green" />
                                            )}
                                        </button>
                                    ))}
                                    <div className="text-navy-50 mt-6">{t('Environment')}:</div>
                                    <hr className="my-2" />
                                    {environmentOptions.map(option => (
                                        <button
                                            type="button"
                                            key={option.value}
                                            onClick={() => onChangeEnvironment(option.value)}
                                            className="text-navy-70 my-1 flex w-full cursor-pointer items-center justify-start hover:text-navy"
                                        >
                                            {option.label}
                                            {option.value === environment && (
                                                <Check className="ml-2" color="green" />
                                            )}
                                        </button>
                                    ))}
                                    <div className="text-navy-50 mt-6">{t('Demo Mode')}:</div>
                                    <hr className="my-2" />
                                    <Switch
                                        classNames={{
                                            track: '!cursor-pointer',
                                        }}
                                        checked={demoMode}
                                        onChange={e => onChangeDemoMode(e.target.checked)}
                                        color={helmColors.primaryBlue}
                                    />
                                    <div className="text-navy-50 mt-6">
                                        {t('Force Showing Real Sku Names')}:
                                    </div>
                                    <hr className="my-2" />
                                    <Switch
                                        classNames={{
                                            track: '!cursor-pointer',
                                        }}
                                        checked={forceShowRealSkuNames}
                                        onChange={e =>
                                            onChangeForceShowRealSkuNames(e.target.checked)
                                        }
                                        color={helmColors.primaryBlue}
                                    />
                                    <div className="text-navy-50 mt-6">{t('Show Created By')}:</div>
                                    <hr className="my-2" />
                                    <Switch
                                        classNames={{
                                            track: '!cursor-pointer',
                                        }}
                                        checked={showCreatedBy}
                                        onChange={e => setShowCreatedBy(e.target.checked)}
                                        color={helmColors.primaryBlue}
                                    />
                                </div>
                            )}
                            <div className="mt-4">
                                <HelmLogout />
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div>
        </div>
    );
};
