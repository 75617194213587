import 'dayjs/locale/es';
import { DatesProvider } from '@mantine/dates';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface DatesProviderCompProps {
    children: ReactNode;
}

export const DatesProviderComp: FC<DatesProviderCompProps> = ({ children }) => {
    const { i18n } = useTranslation();
    return <DatesProvider settings={{ locale: i18n.language }}>{children} </DatesProvider>;
};
