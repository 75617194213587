import { MantineProvider } from '@mantine/core';
import Head from 'next/head';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { HEAP_ID } from '../../buildConfig/processEnv';
import { useAppContext } from '../../contexts/appContext';
import ReactHeap from '../../utils/heap';
import AppLoading from '../appLoading';
import Login from '../login';
import i18n from './translations/i18n';
import { helmColors } from './util/colors';
import { installIntercom } from './util/installIntercom';
import useStore from './components/state/state';
import {
    clientIdMap,
    clientIdReverseMap,
    ClientOptions,
    CountryOptions,
    Environment,
} from './util/config';
import HelmLogout from './components/helmLogout';
import { DatesProviderComp } from './components/datesProvider';
import { PrimaryButton } from './components/buttons/primaryButton';
import useRouterQuery from '../../hooks/useRouterQuery';
import { useClientTranslation } from '../../hooks/helm/useClientTranslation';
import { Settings } from './components/settings';

declare const heap: {
    track(eventName: string, properties?: Record<string, any>): void;
};

interface ErrorBoundaryFallbackComponentProps {
    resetErrorBoundary: () => void;
}

const ONEBRAIN_UNAVAILABLE = false;

function ErrorBoundaryFallbackComponent({
    resetErrorBoundary,
}: ErrorBoundaryFallbackComponentProps) {
    return (
        <div className="flex w-full flex-col items-center justify-center" role="alert">
            <p className="text-gray-700">
                Oops, something went wrong. Please refresh and try again.
            </p>
            <div className="my-4">
                <PrimaryButton
                    className="!text-navy-15 !font-semibold"
                    onClick={resetErrorBoundary}
                >
                    Try again
                </PrimaryButton>
            </div>
            <div>
                {' '}
                If the issue persists, please email us at{' '}
                <a
                    style={{ color: helmColors.darkBlue }}
                    href="mailto:onebrain-support@arena-ai.com"
                >
                    onebrain-support@arena-ai.com
                </a>{' '}
                for assistance.
            </div>
        </div>
    );
}

function HelmLayout({ children }: { children: React.ReactNode }) {
    const { router, handleURLParamsChange } = useRouterQuery();
    const { t } = useClientTranslation();
    const demoMode = useStore(state => state.demoMode);
    const setDemoMode = useStore(state => state.setDemoMode);

    const title = useMemo(
        () => (demoMode ? 'OneBrain' : `BEES AI - ${t('powered by').toLowerCase()} Arena`),
        [demoMode, t],
    );

    const { user, fetchingToken, verifyToken, setEnvironment } = useAppContext();
    const groups = user?.groups;

    useEffect(() => {
        if (typeof heap !== 'undefined') {
            heap.track('User Group Logged', {
                userGroups: user?.groups,
            });
        }
    }, [user?.groups]);

    const client = useStore(state => state.client);
    const setClient = useStore(state => state.setClient);
    const setCountry = useStore(state => state.setCountry);

    useEffect(() => {
        if (!router.isReady) return;
        const routerDemoMode = router.query.demoMode;
        if (routerDemoMode) {
            setDemoMode(routerDemoMode === 'true');
        }
        const routerCountry = router.query.country as CountryOptions;
        if (routerCountry) {
            setCountry(routerCountry);
        }

        if (client) return;
        if (!groups || !groups.length) return;
        if (groups.includes('arena-staff')) {
            const routerClient = router.query.client as ClientOptions;
            const storedClient = localStorage.getItem('client');
            const clientValue =
                clientIdReverseMap[routerClient] ??
                (storedClient as ClientOptions) ??
                ClientOptions.Neptune;

            localStorage.setItem('client', clientValue);
            setClient(clientValue);
            handleURLParamsChange({
                key: 'client',
                value: clientIdMap[clientValue],
            });
        } else if (groups.includes('nestle-users')) {
            setClient(ClientOptions.Neptune);
        } else if (groups.includes('abi-users')) {
            setClient(ClientOptions.AlphaC);
        }
    }, [
        client,
        handleURLParamsChange,
        router.isReady,
        router.query.country,
        router.query.demoMode,
        router.query.client,
        setClient,
        setCountry,
        setDemoMode,
        groups,
    ]);

    useEffect(() => {
        const { firstName, lastName, username } = user;
        setTimeout(() => {
            if (firstName && lastName && username) {
                installIntercom({
                    name: `${user?.firstName} ${user?.lastName}`,
                    email: user.username,
                });
            }
        }, 5000);

        if (HEAP_ID) {
            ReactHeap.initialize(HEAP_ID);
            if (typeof window !== 'undefined' && window.heap && window.heap.identify) {
                window.heap.identify(user.username);
                window.heap.addUserProperties({ groups: user?.groups && user?.groups.join(', ') });
            }
        }
    }, [user]);

    const { i18n: currentI18n } = useTranslation();

    // TODO: i put this in a useEffect but then it was one step behind. Not sure best practices.
    moment.locale(currentI18n.language);

    useEffect(() => {
        if (!user || !user?.showUserDetails) return;
        function checkDetails() {
            const timeout = Number(localStorage.getItem('detailsTimeout'));
            if (timeout && timeout !== moment().day()) {
                localStorage.removeItem('detailsSubmitted');
                localStorage.removeItem('detailsTimeout');
            }
        }
        checkDetails();
    }, [router, user, user?.showUserDetails]);

    useEffect(() => {
        // This is a hack to change intercom chatbot button position only for overview page.
        const intervalId = setInterval(() => {
            const chatbotButton = document.querySelector('.intercom-launcher');
            const classValue = '!bottom-[16px]';

            if (chatbotButton) {
                if (router.pathname === '/') {
                    if (!chatbotButton.classList.value.includes(classValue)) {
                        chatbotButton.classList.add(classValue);
                    } else {
                        clearInterval(intervalId);
                    }
                } else {
                    chatbotButton.classList.remove(classValue);
                    clearInterval(intervalId);
                }
            }
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, [router]);

    useEffect(() => {
        if (router.query.environment) {
            setEnvironment(router.query.environment as Environment);
        }
    }, [router, setEnvironment]);

    useEffect(() => {
        verifyToken(user.token);
    }, [user.token, verifyToken]);

    const noUserGroups = useMemo(() => user?.groups?.length === 0, [user?.groups?.length]);

    if (fetchingToken) return <AppLoading>Authenticating user</AppLoading>;
    if (!user || !user.token || !user.isLoggedIn) return <Login />;
    if (!user?.groups) return <AppLoading>Loading user data</AppLoading>;

    if (ONEBRAIN_UNAVAILABLE) {
        return (
            <div className="flex h-screen flex-col items-center justify-center space-x-4 space-y-6 bg-white text-navy">
                <div className="flex flex-col items-center justify-center space-y-4">
                    <div>BEES.ai (OneBrain) is currently under maintenance.</div>
                    {/* <HelmLogout /> */}
                    <div>
                        {' '}
                        If you have any questions, please email us at{' '}
                        <a
                            style={{ color: helmColors.darkBlue }}
                            href="mailto:onebrain-support@arena-ai.com"
                        >
                            onebrain-support@arena-ai.com
                        </a>{' '}
                        for assistance.
                    </div>
                </div>
            </div>
        );
    }

    if (noUserGroups) {
        return (
            <div className="flex h-screen flex-col items-center justify-center space-x-4 space-y-6 bg-white text-navy">
                <div className="flex flex-col items-center justify-center space-y-4">
                    <div>
                        We’ve made some new updates! Please try logging out and logging back in to
                        update.
                    </div>
                    <HelmLogout />
                    <div>
                        {' '}
                        If the issue persists, please email us at{' '}
                        <a
                            style={{ color: helmColors.darkBlue }}
                            href="mailto:onebrain-support@arena-ai.com"
                        >
                            onebrain-support@arena-ai.com
                        </a>{' '}
                        for assistance.
                    </div>
                </div>
            </div>
        );
    }

    // TODO: add logic to hide and redirect if they shouldn't be allowed here

    return (
        <div className="helm-scrollbar relative flex h-screen bg-white text-black">
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackComponent}>
                <I18nextProvider i18n={i18n}>
                    <DatesProviderComp>
                        <MantineProvider
                            theme={{
                                fontFamily: 'inherit',
                                colors: {
                                    lightBlue: [
                                        helmColors.lightBlue,
                                        '#ccd2e6',
                                        '#b8c0d6',
                                        '#a4aec6',
                                        '#909cb6',
                                        '#7c8aa6',
                                        '#687896',
                                        '#546686',
                                        '#404476',
                                        '#2c3266',
                                    ],
                                },
                            }}
                        >
                            {children}
                            <Settings />
                        </MantineProvider>
                    </DatesProviderComp>
                </I18nextProvider>
            </ErrorBoundary>
        </div>
    );
}

export default HelmLayout;
