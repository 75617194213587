import useStore from '../../components/helm/components/state/state';
import { useAppContext } from '../../contexts/appContext';

type ValueType<T> = T[keyof T];
export type OrientSplit<T> = {
    columns: (keyof T)[];
    data: ValueType<T>[][];
};

export function orientSplitToObjects<T>(input: OrientSplit<T> | undefined): T[] {
    if (!input) return [];

    const { data, columns } = input || {};

    if (!data || !columns) return [];

    return data.reduce((acc: T[], row: ValueType<T>[]) => {
        const obj = row.reduce(
            (accumulator: { [key: string]: ValueType<T> }, item: ValueType<T>, index: number) => {
                accumulator[columns[index] as string] = item;
                return accumulator;
            },
            {},
        );
        acc.push(obj as T);
        return acc;
    }, []);
}

export type WithLiters<T> = T & { liters: number };

type WithWeight<T> = T & {
    weight: number;
    weight_cumsum: number;
    revenue: number;
    revenue_cumsum: number;
};

export const convertProjectionData = <T>(
    data: WithWeight<T>,
): {
    // TODO: make resusable type here
    hectaLitersDaily: number;
    hectaLitersCumulative: number;
    revenueDaily: number;
    revenueCumulative: number;
    projection: boolean;
} => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { weight, weight_cumsum, revenue_cumsum, revenue, ...rest } = data;
    return {
        ...rest,
        projection: true,
        revenueDaily: revenue,
        revenueCumulative: revenue_cumsum,
        hectaLitersDaily: (weight || 0) / 100,
        hectaLitersCumulative: (weight_cumsum || 0) / 100,
    };
};

export const generateUniqueId = () =>
    Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
